<template>
    <div class="email_comp">
        <div class="form_grp">
            <div class="group_item">
                <div class="input_label">
                    Subject Line
                    <emoji-picker :onSelectEmoji="insertEmoji" classes="subject-emoji-picker" :ref="`emoji-${emailUuid}`" v-click-outside="hideEmoji" />
                    <div class="dropdown mr-3" @click="personalizeOption = !personalizeOption" v-click-outside="hidePersonalizeOption">
                        <i class="fas fa-user-alt"></i>
                        <div class="drp_wrapper" v-if="personalizeOption" :class="{ active : personalizeOption }">
                            <ul>
                                <li v-for="(personalize, p) of defaultPersonalize" @click="insertPersonalize(personalize)" :key="p">{{ personalize }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="field_wpr" :class="{ 'has-error': errors.subject  }">
                    <Field autocomplete="off" type="text" name="subject" placeholder="Type Subject" v-model="form.subject" :id="emailUuid" rules="required" label="subject" />
                </div>
                <ErrorMessage name="subject" class="text-danger" />
            </div>
        </div>
        <div class="form_grp">
            <div class="group_item">
                <label class="input_label" v-click-outside="hideMsgOption">
                    Message
                    <div class="dropdown" @click="msgOption = !msgOption">
                        <i class="fas fa-ellipsis-h"></i>
                        <div class="drp_wrapper" v-if="msgOption" :class="{ active: msgOption }">
                            <ul>
                                <li @click="resetToDefault()">Reset to default message</li>
                                <li @click="templateLibrary = !templateLibrary" :class="{ active: templateLibrary }">Import Template</li>
                                <li @click="sendTestEmail = !sendTestEmail" :class="{ active: sendTestEmail }">Send test email</li>
                                <li @click="previewTestEmail = !previewTestEmail" :class="{ active: previewTestEmail }">Preview in browser</li>
                            </ul>
                        </div>
                    </div>
                </label>
                <div class="editorWpr" :class="{ 'has-error': errors.email_content }">
                    <Field autocomplete="off" name="email_content" v-model="form.email" :class="{ 'has-error': errors.email_content }" rules="required" label="email content">
                        <redactor v-model="form.email" :default-value="editorDefaultValue" :reset-watcher="resetToDefaultWatcher" :default-value-watcher="editorDefaultValueWatcher" name="email_content" classlist="right_out" :has-import-template="true" :personalize="personalize" :import-template-handler="toggleTemplateDrawer" />
                    </Field>
                </div>
                <ErrorMessage name="email_content" class="text-danger" />
                <template-library v-model="templateLibrary" :on-import="handleTemplateImport" type="email" />
                <send-email-sms-preview v-model="sendTestEmail" :is-preview="previewTestEmail" @closePreview="previewTestEmail = value" :post-params="form" module="email" :type="1" />
            </div>
        </div>
        <label for="logo" class="switch_option capsule_btn">
            <h5>Show Email Logo</h5>
            <input type="checkbox" :true-value="1" :false-value="0" v-model="form.show_email_logo" id="logo" hidden>
            <div><span></span></div>
        </label>
        <div v-show="form.show_email_logo" class="upload_image">
            <image-library v-model="form.email_logo" image-type="public-logo" upload-text="Logo" ref="email-logo-upload" />
        </div>
        <label for="signature" class="switch_option capsule_btn">
            <h5>Show Email Signature</h5>
            <input type="checkbox" :true-value="1" :false-value="0" v-model="form.is_signature" id="signature" hidden>
            <div><span></span></div>
        </label>
        <div v-show="form.is_signature" class="form_grp">
            <div class="group_item">
                <label class="input_label">Signature <a class="option" @click="createSignature = !createSignature">Create Signature</a></label>
                <div class="field_wpr">
                    <multiselect
                        v-model="form.signature_id"
                        :options="signatures"
                        placeholder="Select signature"
                        value-prop="id"
                        label="signature_name"
                        :searchable="true"
                    ></multiselect>
                </div>
            </div>
        </div>
        <create-signature v-model="createSignature" v-show="form.is_signature" v-if="!isFullView" />
        <add-signature v-model="createSignature" v-show="form.is_signature" v-if="isFullView" />
        <label for="smschedule" class="switch_option capsule_btn">
            <h5>Schedule Email</h5>
            <input type="checkbox" :true-value="1" :false-value="0" v-model="emailSchedule" id="smschedule" hidden>
            <div><span></span></div>
        </label>
        <div v-show="emailSchedule" class="schedule_sms">
            <h3 class="sub_header">Schedule Email</h3>
            <div class="edit_section">
                <schedule-tab v-model="schedule" ref="launchpad-email-schedule" />
                <div class="action_wpr mt-2">
                    <button type="button" class="btn save_btn" @click="scheduleEmail"><i class="fa fa-spinner fa-spin" v-if="scheduling"></i> {{ scheduling ? 'Scheduling' : 'Schedule' }}</button>
                </div>
            </div>
        </div>
    </div>
    <import-template v-model="importTemplate" :handle-import="handleTemplateImport" />
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, ErrorMessage } from 'vee-validate'
    import { uuid } from 'vue-uuid'
    import { mapState, mapActions, mapMutations } from 'vuex'

    const ScheduleTab = defineAsyncComponent(() => import('@/components/ScheduleTab'))
    const TemplateLibrary = defineAsyncComponent(() => import('@/components/TemplateLibrary'))
    const CreateSignature = defineAsyncComponent(() => import('@/components/CreateSignature'))
    const SendEmailSmsPreview = defineAsyncComponent(() => import('@/components/SendEmailSmsPreview'))
    const EmojiPicker = defineAsyncComponent(() => import('@/components/EmojiPicker'))
    const ImportTemplate = defineAsyncComponent(() => import('@/components/ImportTemplate'))
    const AddSignature = defineAsyncComponent(() => import('@/pages/admin-setting/components/signature/AddSignature'))

    import Toastr from '@/utils/Toastr'
    import constants from '@/utils/Constant'

    export default {
        name: "Email Component",

        data () {
            return {
                emailUuid: 'email-'+uuid.v4(),
                emailSchedule: false,
                schedule: {},
                form: {
                    source: 'contacts',
                    subject: '',
                    email: '',
                    is_email_conf: 0,
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                    contact_id: '',
                },
                defaultPersonalize: [],
                personalizeOption: false,
                sendTestEmail: false,
                previewTestEmail: false,
                msgOption: false,
                createSignature: false,
                scheduling: false,
                templateLibrary:false,
                scheduleAction: '/contacts/send-email',
                defaultContent: {},
                editorDefaultValue: '',
                resetToDefaultWatcher: 0,
                editorDefaultValueWatcher: 0,
                importTemplate: false,
            };
        },

        props:{
            name: String,
            modelValue: Object,
            contacts: [String, Number],
            errors: [Object, Array],
            placeholder: {
                type: String,
                default: 'Type message',
            },
            componentType: {
                type: String,
                default: 'email',
            },
            personalize: {
                type: [Object, Array],
                default: () => [],
            },
            successHandler: Function,
            handleDefaultEmail: Function,
            isFullView: {
                type: Boolean,
                default: false
            }
        },

        emits: ['update:modelValue', 'update:email'],

        components:{
            Field,
            EmojiPicker,
            ScheduleTab,
            ErrorMessage,
            AddSignature,
            CreateSignature,
            TemplateLibrary,
            SendEmailSmsPreview,
            ImportTemplate,
        },

        watch: {
            form: {
                handler (form) {
                    const vm = this;
                    vm.$emit('update:modelValue', form);
                },
                deep: true
            },

            modelValue (form) {
                if (form.email) {
                    const vm = this;

                    vm.editorDefaultValue = form.email;

                    setTimeout(function () {
                        vm.editorDefaultValueWatcher += 1;
                        vm.resetToDefaultWatcher += 1;
                    }, 500);
                }
            },
        },

        computed: mapState({
            user: state => state.authModule.user,
            signatures: state => state.signatureModule.signatures,
        }),

        mounted () {
            const vm = this;

            vm.defaultPersonalize = constants.personalize.concat(vm.personalize);
            vm.defaultContent     = vm.modelValue;

            if (vm.signatures.length == 0) {
                vm.getSignature();
            }

            vm.resetToDefault(true);
        },

        methods:{
            ...mapActions({
                getSignature: 'signatureModule/getSignature',
                sendEmail: 'contactModule/sendEmail',
                changeMemberIDEmail: 'contactModule/changeMemberIDEmail',
            }),

            ...mapMutations({
                setBulkActionWatcher: 'contactModule/SET_BULK_ACTION_WATCHER',
            }),

            insertEmoji (emoji) {
                const vm = this;
                const textarea    = document.getElementById(vm.emailUuid);
                const caretPos    = textarea.selectionStart;
                const textAreaTxt = textarea.value;
                const txtToAdd    = emoji;

                vm.form.subject = textAreaTxt.substring(0, caretPos) + ' ' +  txtToAdd + ' '  + textAreaTxt.substring(caretPos);
            },

            insertPersonalize (personalize) {
                const vm = this;
                const textarea    = document.getElementById(vm.emailUuid);
                const caretPos    = textarea.selectionStart;
                const textAreaTxt = textarea.value;
                const txtToAdd    = `{{ ${ personalize.toLowerCase().replaceAll(' ', '_') } }}`;

                vm.form.subject = textAreaTxt.substring(0, caretPos) + ' ' +  txtToAdd + ' '  + textAreaTxt.substring(caretPos);
            },

            resetToDefault (mounted = false) {
                const vm = this;

                if (!mounted && vm.defaultContent.defaultHandler) {
                    vm.handleDefaultEmail();
                } else if (vm.defaultContent.email) {
                    vm.form = JSON.parse(JSON.stringify(vm.defaultContent));
                    vm.form.email_logo = vm.user.logo;
                    vm.resetToDefaultWatcher += 1;
                    vm.editorDefaultValueWatcher += 1;

                    if (vm.form.schedule_action) {
                        vm.scheduleAction = vm.form.schedule_action;
                    } else {
                        vm.scheduleAction = '/contacts/send-email';
                    }
                } else {
                    vm.form = {
                        source: 'contacts',
                        subject: '',
                        email: '',
                        is_email_conf: 0,
                        is_email_logo: 0,
                        is_signature: 0,
                        signature_id: 0,
                        show_email_logo: 0,
                        email_logo: vm.user.logo,
                        contact_id: vm.contacts,
                    };

                    vm.scheduleAction = '/contacts/send-email';
                }
            },

            handleTemplateImport (template) {
                const vm = this;

                vm.form.subject = template.subject;
                vm.form.email = template.email_content;
                vm.editorDefaultValue = template.email_content;
                vm.resetToDefaultWatcher += 1;
            },

            scheduleEmail () {
                const vm = this;
                const logoUploadComponent = vm.$refs['email-logo-upload'];
                const params = JSON.parse(JSON.stringify(vm.form))
                params.is_email_logo = logoUploadComponent.status;

                if (vm.$parent.$parent && vm.$parent.$parent.validate) {
                    vm.$parent.$parent.validate().then((result) => {
                      if (result.valid) {
                          const schedule = vm.$refs['launchpad-email-schedule'];
                          vm.scheduling = true;

                          schedule.scheduleDelay(vm.scheduleAction, params).then((resp) => {
                              vm.scheduling = false;
                              vm.schedule = false;
                              schedule.refreshDelay();
                              vm.setBulkActionWatcher();

                              if (vm.successHandler) {
                                  vm.successHandler();
                              }

                              Toastr.success(`The ${vm.componentType} has been scheduled successfully!`);
                          }).catch((err) => {
                              vm.scheduling = false;
                              Toastr.handleServerError(err);
                          });
                      } else {
                          Toastr.error(constants.form_error);
                      }
                    });

                } else {
                    vm.$parent.validate().then((result) => {
                        if (result.valid) {
                            const schedule = vm.$refs['launchpad-email-schedule'];
                            vm.scheduling = true;

                            schedule.scheduleDelay(vm.scheduleAction, params).then((resp) => {
                                vm.scheduling = false;
                                vm.schedule = false;
                                schedule.refreshDelay();
                                vm.setBulkActionWatcher();

                                if (vm.successHandler) {
                                    vm.successHandler();
                                }

                                Toastr.success(`The ${vm.componentType} has been scheduled successfully!`);
                            }).catch((err) => {
                                vm.scheduling = false;
                                Toastr.handleServerError(err);
                            });
                        } else {
                          Toastr.error(constants.form_error);
                        }
                    });
                }
            },

            handleEmailSend (action = 'sendEmail') {
                const vm = this;
                const logoUploadComponent = vm.$refs['email-logo-upload'];
                const params = JSON.parse(JSON.stringify(vm.form))
                params.is_email_logo = logoUploadComponent.status;

                if (vm.$parent.$parent && vm.$parent.$parent.validate) {
                    vm.$parent.$parent.validate().then((result) => {
                        if (result.valid) {
                            vm[action](params).then((result) => {
                                if (result && vm.successHandler) {
                                    vm.successHandler();
                                }
                            });
                        } else {
                            Toastr.error(constants.form_error);
                        }
                    });
                } else {
                    vm.$parent.validate().then((result) => {
                        if (result.valid) {
                            vm[action](params).then((result) => {
                                if (result && vm.successHandler) {
                                    vm.successHandler();
                                }
                            });
                        } else {
                            Toastr.error(constants.form_error);
                        }
                    });
                }
            },

            hidePersonalizeOption () {
                const vm = this;

                vm.personalizeOption = false;
            },

            hideMsgOption () {
                const vm = this;

                vm.msgOption = false;
            },

            hideEmoji () {
                const vm = this;
                const emoji = vm.$refs[`emoji-${vm.emailUuid}`];

                if (emoji && emoji.toggle) {
                    emoji.toggle = false;
                }
            },

            toggleTemplateDrawer () {
                const vm = this;

                vm.importTemplate = true;
            },
        },
    }
</script>

<style scoped>
    :deep(.v3-input-emoji-picker) {
        margin: 0 0 0 auto;
    }

    :deep(.subject-emoji-picker .custom-emoji-picker) {
        top: 0;
    }

    :deep(.v3-input-picker-icon) {
        height: 0;
    }

    :deep(.emoji-picker.subject-emoji-picker) {
        margin: 0 0 0 auto;
    }

    .input_label {
        position: relative;
    }

    :deep(.subject-emoji-picker) {
        position: absolute;
        right: 0;
    }

    :deep(.subject-emoji-picker .dropdown-toggle) {
        margin-right: 40px;
    }
</style>
